import { BrowserRouter, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Main from "./logged_out/Main";
import "./scss/style.scss";

function ScrollToTop(props: any) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);
  return props.children;
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop>
          <Main />
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}
export default App;


// user: hntinda@edsnamibia.com
// Pssw: #EdsNamibia@2023