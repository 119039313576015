export const clientsData = [
  {
    id: "25",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard25.jpeg`,
  },
  {
    id: "26",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard26.jpeg`,
  },
  {
    id: "27",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard27.jpeg`,
  },
  {
    id: "28",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard28.jpeg`,
  },
  {
    id: "29",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard29.jpeg`,
  },
  {
    id: "30",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard30.jpeg`,
  },
  {
    id: "31",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard31.jpeg`,
  },
  {
    id: "0",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard0.jpg`,
  },
  {
    id: "1",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard1.jpg`,
  },
  {
    id: "2",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard2.jpg`,
  },
  {
    id: "3",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard3.jpg`,
  },
  {
    id: "4",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard4.jpg`,
  },
  {
    id: "5",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard5.jpg`,
  },
  {
    id: "6",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard6.jpg`,
  },
  {
    id: "7",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard7.jpg`,
  },
  {
    id: "8",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard8.jpg`,
  },
  {
    id: "9",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard9.jpg`,
  },
  {
    id: "10",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard10.jpg`,
  },
  {
    id: "11",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard12.jpg`,
  },
  {
    id: "12",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard11.jpg`,
  },
  {
    id: "13",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard13.jpg`,
  },
  {
    id: "14",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard14.jpg`,
  },
  {
    id: "15",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard15.jpg`,
  },
  {
    id: "16",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard16.jpg`,
  },
  {
    id: "17",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard17.jpg`,
  },
  {
    id: "18",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard18.jpg`,
  },
  {
    id: "19",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard19.png`,
  },
  {
    id: "20",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard20.png`,
  },
  {
    id: "21",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard21.png`,
  },
  {
    id: "22",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard22.png`,
  },
  {
    id: "23",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard23.png`,
  },
  {
    id: "24",
    photo: `${process.env.PUBLIC_URL}/images/clients/Artboard24.png`,
  },
];

export const clientCardData = [
  {
    id: "7",
    fullname: "Oliver Tors",
    abbrev: "OT",
    company: "Exploration Manager Africa, Antler Gold Pty Ltd",
    description: `“Excel Dynamic Solutions Pty provided exceptional geological services to Antler Gold Namibia Pty Ltd on the recent exploration project in the Karibib District. Their team demonstrated an unparalleled level of expertise and commitment throughout the entire exploration process.”`,
  },
  {
    id: "8",
    fullname: "Philip Andrews, ",
    abbrev: "PA",
    company: "CEO, Resource 500 Namibia Pty Ltd CEO",
    description: `“EDS provided a full package of services related to the obtaining of the Environmental Clearance Certificates for 8 EPLs in Namibia. The work was carried out punctually, professionally and successfully.”`,
  },
  {
    id: "9",
    fullname: "Careful Kaeka",
    abbrev: "CK",
    company: "Manager: Environment & Community Sustainability, Trigon Mining (Namibia) (Pty) Ltd",
    description: `“Excel Dynamic (Pty) Ltd performed environmental and hydrogeological assessments and permitting for our exploration and mining activities in a fully satisfactory manner, meeting the standards set in the relevant contract and work order.”`,
  },
  {
    id: "1",
    fullname: "Mr. Robert Middleton",
    company: "White Metals Limited",
    abbrev: "RM",
    description: `“Excel Dynamic Solutions Pty conducted reports on the 
    environment and community relations for our Canadian Company in Namibia 
    and did an excellent job and completed the work on time and on budget to
     our great satisfaction. We recommend using Nerson and his team for this
      type of work.”`,
  },
  {
    id: "2",
    fullname: "Mr. Feng Wu ",
    abbrev: "FW",
    company: "Southwest Mining Investment Pty Ltd",
    description: `“EDS has given us professional service in Environmental 
    Impact Assessment and Environmental Management Plan on our Exclusive Prospective 
    Licenses. As a newly established mining company in Namibia, we obtained
     Environmental Clearance Certifications smoothly and timely by EDS. The 
     service and the report they gave are quite impressive, we now keep them 
     as our long-term service provider. Feel free to contact me for any 
     questions you might have.”`,
  },
  {
    id: "3",
    fullname: "Dr. Mary Seely",
    abbrev: "MS",
    company: "Desert Research Foundation of Namibia",
    description: `“Nerson and his team were responsible for the electronic capturing,
     cleansing and analysis of long-term ecological (records from 60s) and climate data 
     from Gobabeb recording stations.  The team did an excellent job despite facing challenges
     of trying to make sense of handwritten records.”`,
  },
  {
    id: "4",
    fullname: "Mr. Johannes Brunner",
    abbrev: "JB",
    company: "Manger Mining Pty Ltd",
    description: `“Excel Dynamic Solutions consultants have in the past few years assisted Manger
     Mining with various aspects of geological reporting, auditing, and environmental assessments.
      Their staff played a significant role in assisting us with the renewal of our mining license, 
      for which we are incredibly grateful. We have found that all their staff we have had dealings
       with have been professional, helpful, and willing to go that extra mile to get a job done.
        We continue to rely on their expertise and would have no hesitation in recommending their
         services.”`,
  },
  {
    id: "5",
    fullname: "Dr. Cornelis Van Der Waal",
    abbrev: "CVDW",
    company: "Agri-Ecological Services",
    description: `“Mr. Tjelos had been recommended to me by Dr Nicky Knox from the Namibia University
     of Science and Technology to join a multi- discipline project of the Ministry of Land Reform. In
      this project, which aims to revise the existing national Agro-Ecological Zone and Carrying Capacity Maps,
       I work closely with Mr. Tjelos and his colleagues on climate matters.
    I can highly recommend Mr. Tjelos and colleagues to assist with coordination
     and advice relating to environmental issues.”`,
  },
  {
    id: "6",
    fullname: "Dr. Peter Schreck",
    abbrev: "PS",
    company: "Geo Experts Consulting Services",
    description: `“Excel Dynamic Solutions Pty Ltd is a trustworthy company I have worked with on a couple of projects for many years.”`,
  },
];