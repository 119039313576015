export const awardsData = [
  {
    id: "1",
    photo: `${process.env.PUBLIC_URL}/images/awards/Buildit.png`,
    text: " Best Geology and Environmental Services in Namibia award by BUILD, UK. 2022",
  },
  {
    id: "2",
    photo: `${process.env.PUBLIC_URL}/images/awards/Startupper.png`,
    text: "Top 10 finalists in the Total Startupper of the Year Challenge, 2019.",
  },
  {
    id: "3",
    photo: `${process.env.PUBLIC_URL}/images/awards/Development.jpg`,
    text: " Top 10 finalists in the Development Bank of Namibia Innovation Award, 2018.",
  },
  {
    id: "4",
    photo: `${process.env.PUBLIC_URL}/images/awards/Southern.png`,
    text: " Southern Africa Startup Awards Finalist – Water Management Solutions, 2018.",
  },
  {
    id: "5",
    photo: `${process.env.PUBLIC_URL}/images/awards/Tonyelumelu.jpg`,
    text: "Tony Elumelu Foundation Entrepreneurship Programme Participant, 2018.",
  },
  {
    id: "6",
    photo: `${process.env.PUBLIC_URL}/images/awards/African.jpg`,
    text: "African Entrepreneurship Awards (by African Development Bank) Stage II or Semi-Finalist, 2018.",
  },
  {
    id: "7",
    photo: `${process.env.PUBLIC_URL}/images/awards/sanlam.jpg`,
    text: "Namibia Business Startupper of the year finalist 2017: SANLAM in collaboration with Namibia Business Innovation.",
  },
  {
    id: "8",
    photo: `${process.env.PUBLIC_URL}/images/awards/Namibia.png`,
    text: "Namibian Business Hall of Fame Induction, 2017.",
  },
];
