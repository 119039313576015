import { Routes, Route, Navigate } from "react-router-dom";
import { Footer } from "./landing/footer/Footer";
import { Landing } from "./landing/home/Home";
import { ServiceDetail } from "./landing/services/ServicesDetail";
import Drawer from "./nagivation/Drawer";
import NavBar from "./nagivation/NavBar";

const NavigationRoutes = () => {
  return (
    <Routes>
      <Route path={`/`} element={<Landing />} />
      <Route path={`/:id`} element={<ServiceDetail />} />
      <Route path={`/*/*`} element={<Navigate to={"/"} />} />
      <Route path="*" element={<Navigate to={"/"} />} />
    </Routes>
  );
};

const Main = () => {
  return (
    <div className="responsive-drawer">
      <Drawer />
      <main className="content">
        <NavBar />
        <NavigationRoutes />
        <Footer />
      </main>
    </div>
  );
};

export default Main;
