/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { homeData } from "../data/home/home";
import { serviceData } from "../data/services/serviceData";
import { teamData } from "../data/team/team";
import { escape } from "lodash";
import { awardsData } from "../data/awards/awardsData";
import { clientsData, clientCardData } from "../data/clients/clientsData";
import { ServiceCard } from "../services/ServicesDetail";

export interface IContactForm {
  fullname: string;
  email: string;
  company: string;
  subject: string;
  message: string;
  belong: string;
}

export const defaultContactForm: IContactForm = {
  fullname: "",
  email: "",
  company: "",
  subject: "",
  message: "",
  belong: "",
};

interface IProp {
  isActive: boolean;
  title: string;
  onClick: () => void;
}
const Tab = (props: IProp) => {
  const { isActive, title, onClick } = props;
  const activeClass = isActive ? "active" : "";

  return (
    <button
      className={`uk-button uk-button tab-button ${activeClass}`}
      onClick={onClick}
    >
      <h3 className="uk-card-title"> {title}</h3>
    </button>
  );
};

interface IProps {
  tab: "WHO" | "MISSION" | "VISION";
  setTab: React.Dispatch<React.SetStateAction<"WHO" | "MISSION" | "VISION">>;
}
const CardTabs = (props: IProps) => {
  const { tab, setTab } = props;

  const setWho = () => {
    setTab("WHO");
  };
  const setMission = () => {
    setTab("MISSION");
  };
  const setVission = () => {
    setTab("VISION");
  };

  return (
    <div className="uk-margin">
      <Tab isActive={tab === "WHO"} title="WHO WE ARE" onClick={setWho} />
      <Tab isActive={tab === "MISSION"} title="MISSION" onClick={setMission} />
      <Tab isActive={tab === "VISION"} title="VISION" onClick={setVission} />
    </div>
  );
};

export const Landing = observer(() => {
  const [tab, setTab] = useState<"WHO" | "MISSION" | "VISION">("WHO");
  const disclaimer = `${process.env.PUBLIC_URL}/docs/Company profile 2023.pdf`;

  const [loading, setLoading] = useState(false);
  const [contactForm, setContactForm] = useState<IContactForm>({
    ...defaultContactForm,
  });

  const API_PATH = "http://edsnamibia.com/php/excel.php?";

  const postMail = (path: string) => {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        alert("Send");
      }
      if (this.readyState === 4 && this.status === 500) {
        alert("Failed, try again.");
      }
    };
    xhttp.open("GET", path, true);
    xhttp.send();
  };

  const onSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setLoading(true);
    try {
      const recipient = "info@edsnamibia.com.na";
      const alt_recipient = "info@edsnamibia.com.na";
      const fullname = contactForm.fullname;
      const email = contactForm.email;
      const subject = contactForm.subject;
      const message = contactForm.message;
      const belong = contactForm.belong;

      const url = `fullname=${escape(fullname!)}
          &email=${escape(email)}
          &subject=${(escape(belong), escape(subject))}
          &belong=${escape(belong)}
          &message=${escape(message)}
          &recipient=${escape(recipient)}
           &alt_recipient=${escape(alt_recipient)}`;

      postMail(API_PATH + url);
      setContactForm(defaultContactForm);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };
  return (
    <>
      {/* landing section */}
      <div className="uk-section landing-section" id="excel-dynamic-solutions">
        <div className="uk-container uk-container-large">
          <div
            className="uk-position-relative uk-visible-toggle uk-light"
            data-uk-slideshow="autoplay: true; animation: fade"
          >
            <ul className="uk-slideshow-items">
              {homeData.map((data) => (
                <li key={data.id}>
                  <img src={data.photo} alt="" data-uk-cover />
                  <div className="uk-position-cover opacity-container"></div>
                  <div className="uk-position-center uk-position-small uk-text-center uk-light">
                    <h2 className="uk-margin-remove landing-title">
                      {data.title}
                    </h2>
                    <div className="underline">
                      <div className="line"></div>
                    </div>
                    <p className="uk-margin-remove landing-text">
                      {data.description}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
            <a
              className="uk-position-center-left uk-position-small uk-hidden-hover slider-arrow previous-arrow"
              href="#"
              data-uk-slidenav-previous
              data-uk-slideshow-item="previous"
            ></a>
            <a
              className="uk-position-center-right uk-position-small uk-hidden-hover slider-arrow next-arrow"
              href="#"
              data-uk-slidenav-next
              data-uk-slideshow-item="next"
            ></a>
            <ul className="uk-slideshow-nav uk-dotnav uk-flex-center"></ul>
          </div>
        </div>
      </div>
      {/* about section */}
      <div className="uk-section about-us-section" id="about">
        <div className="uk-container uk-container-large">
          <div className="page-title">
            <span className="uk-margin-right">02</span>ABOUT US
          </div>
          <div className="uk-card">
            <div
              className="uk-grid uk-grid-small uk-grid-match uk-child-width-1-2@m"
              data-uk-grid
            >
              <div>
                <div className="uk-card mission-vision">
                  <div className="uk-card-header">
                    <CardTabs tab={tab} setTab={setTab} />
                  </div>
                  <div className="uk-card-body">
                    {tab === "WHO" && (
                      <p>
                        Excel Dynamic Solutions (PTY) LTD is an independent,
                        diverse, award-winning consulting firm specialising in
                        natural resource discovery and management, project
                        management, big data technology and analytics, and
                        business reengineering. Our team consists of
                        geoscientists, environmentalists, business engineers,
                        data scientists, technologists, and project managers.
                      </p>
                    )}
                    {tab === "MISSION" && (
                      <p>
                        Help transform Africa by providing well packaged
                        professional consulting services and products needed for
                        continental growth and development while ensuring
                        internationally acceptable standards.
                      </p>
                    )}
                    {tab === "VISION" && (
                      <p>
                        To be a high performance multi-disciplinary scientific
                        and technology driven consulting company in Africa.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="uk-card values">
                  <div className="uk-card-header">
                    <h3 className="uk-card-title">VALUES</h3>
                  </div>
                  <div className="uk-card-body">
                    <ul data-uk-accordion>
                      <li className="uk-open">
                        <a className="uk-accordion-title" href="#">
                          Growth Mindset
                        </a>
                        <div className="uk-accordion-content">
                          <p>
                            We strive to grow as a team by continuously
                            exploring new opportunities and markets across
                            Africa.
                          </p>
                        </div>
                      </li>
                      <li>
                        <a className="uk-accordion-title" href="#">
                          Quality Service
                        </a>
                        <div className="uk-accordion-content">
                          <p>
                            The ethos of quality service is of core importance
                            to us. We are deeply focused on the needs of our
                            clients and are dedicated to ensuring that they are
                            satisfied, and we leave them in a better place.
                          </p>
                        </div>
                      </li>
                      <li>
                        <a className="uk-accordion-title" href="#">
                          Accountability and Integrity
                        </a>
                        <div className="uk-accordion-content">
                          <p>
                            We adhere to being accountable because we know it is
                            how we are going to make sure things get done on
                            time and within budgets. Integrity helps us to
                            deliver according to our promises.
                          </p>
                        </div>
                      </li>
                      <li>
                        <a className="uk-accordion-title" href="#">
                          Community Impact
                        </a>
                        <div className="uk-accordion-content">
                          <p>
                            We have always sought to use our skills and
                            resources to contribute to communities’ development
                            and growth.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*services section  */}
      <div className="uk-section service-section" id="services">
        <div className="uk-container uk-container-large">
          <div className="page-title">
            <span className="uk-margin-right">03</span>SERVICES
          </div>

          <div className="uk-card">
            <div
              className="uk-grid uk-grid-small uk-grid-match uk-child-width-1-2@m uk-child-width-1-3@l"
              data-uk-grid
              data-uk-scrollspy="target: .service-card; cls: uk-animation-slide-bottom; delay: 100"
            >
              {serviceData.map((service) => (
                <div key={service.id}>
                  <ServiceCard key={service.id} service={service} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* new awards */}
      <div className="uk-section awards-section" id="awards">
        <div className="uk-container uk-container-large">
          <div className="page-title">
            <span className="uk-margin-right">04</span>AWARDS AND RECOGNITION
          </div>
          <div className="uk-card">
            <div
              className="uk-grid-collapse uk-grid-match uk-child-width-1-3@m uk-child-width-1-4@l uk-text-center uk-margin-large-top"
              data-uk-grid
              data-uk-scrollspy="target: .awards-card; cls: uk-animation-slide-bottom; delay: 100"
            >
              {awardsData.map((award) => (
                <div key={award.id}>
                  <div className="awards-card uk-card uk-card-default uk-card-body awards">
                    <div className="uk-text-center">
                      <img
                        src={award.photo}
                        alt=""
                        className="icon"
                        width={100}
                        height={100}
                      />
                    </div>
                    <div>
                      <span className="text uk-text-small">{award.text}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* awards and recognition 
      <div className="uk-section about-us-section">
        <div className="uk-container uk-container-large">
          <div className="awards-section">
            <div className="uk-card awards-title uk-margin-top">
              <h3 className="uk-card-title">Awards and Recognition</h3>
            </div>
            <div className="uk-card">
              <div
                className="uk-grid-collapse uk-grid-match uk-child-width-1-3@m uk-child-width-1-4@l uk-text-center uk-margin-large-top"
                data-uk-grid
                data-uk-scrollspy="target: .awards-card; cls: uk-animation-slide-bottom; delay: 100"
              >
                {awardsData.map((award) => (
                  <div key={award.id}>
                    <div className="awards-card uk-card uk-card-default uk-card-body awards">
                      <div className="uk-text-center">
                        <img
                          src={award.photo}
                          alt=""
                          className="icon"
                          width={100}
                          height={100}
                        />
                      </div>
                      <div>
                        <span className="text uk-text-small">{award.text}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>*/}
      {/* team section */}
      <div className="uk-section team-section" id="team">
        <div className="uk-container uk-container-large">
          <div className="page-title">
            <span className="uk-margin-right">05</span>TEAM
          </div>
          <div className="uk-card">
            <div
              className="team-card uk-grid uk-grid-match uk-grid-small uk-child-width-1-2@m uk-child-width-1-3@l"
              data-uk-grid="masonry: true"
              data-uk-lightbox="animation: fade"
              data-uk-scrollspy="target: .team-card; cls: uk-animation-slide-bottom; delay: 100"
            >
              {teamData.map((team) => (
                <a
                  key={team.id}
                  href={team.photo}
                  data-caption={team.name}
                  data-alt={team.id}
                >
                  <div>
                    <div className="team-card uk-card uk-card-default">
                      <div className="uk-card-media-top">
                        <img
                          src={team.photo}
                          width="1800"
                          height={900}
                          alt=""
                        />
                      </div>
                      <div className="bottom-content uk-position-bottom">
                        <div>
                          <h3 className="title">{team.name}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* New clients */}
      <div className="uk-section client-section" id="clients">
        <div className="uk-container uk-container-large">
          <div className="page-title">
            <span className="uk-margin-right">06</span>SELECTED CLIENTS
          </div>
          <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex={-1} data-uk-slider="sets: true; autoplay: true" >
            <ul className="uk-margin-bottom uk-slider-items uk-grid uk-grid-small uk-grid-match" data-uk-grid>
              {clientsData.map((client) => (
                <li key={client.id}>
                  <div className="selected-clients-card client-card uk-card uk-padding-small uk-card-default">
                    <div className="uk-flex uk-flex-center">
                      <img
                      className="client-card-image"
                        src={client.photo}
                        alt=""
                        width={150}
                        height={150}
                      />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <a
              className="uk-position-center-left uk-position-small uk-hidden-hover slider-arrow previous-arrow"
              href="#"
              data-uk-slidenav-previous
              data-uk-slider-item="previous"
            ></a>
            <a
              className="uk-position-center-right uk-position-small uk-hidden-hover slider-arrow next-arrow"
              href="#"
              data-uk-slidenav-next
              data-uk-slider-item="next"
            ></a>
          </div>
          <div className="uk-margin-large-top uk-card selected-client-title">
            <h3 className="uk-card-title">Client Reviews</h3>
          </div>
          <div className="uk-card uk-margin-top client-section-2">
            <div
              className="uk-position-relative uk-visible-toggle uk-light"
              tabIndex={-1}
              data-uk-slider="sets: true;autoplay:true"
            >
              <ul
                className="uk-slider-items uk-grid uk-grid-collapse uk-grid-small uk-grid-match uk-child-width-1-2@m uk-child-width-1-3@l"
                data-uk-grid
              >
                {clientCardData.map((client) => (
                  <li key={client.id}>
                    <div className="uk-card uk-card-default uk-padding-small">
                      <div
                        className="uk-grid-small uk-flex-middle"
                        data-uk-grid
                      >
                        <div className="uk-width-auto">
                          <div className="uk-border-circle abbrev">
                            {client.abbrev}
                          </div>
                        </div>
                        <div className="uk-width-expand">
                          <p className="uk-text-bolder uk-margin-remove-bottom">
                            {client.fullname}
                          </p>
                          <p className="uk-text-small uk-margin-remove-top">
                            {client.company}
                          </p>
                        </div>
                      </div>
                      <p className="uk-text-small ">{client.description}</p>
                    </div>
                  </li>
                ))}
              </ul>
              <a
                className="uk-position-center-left uk-position-small uk-hidden-hover slider-arrow previous-arrow"
                href="#"
                data-uk-slidenav-previous
                data-uk-slider-item="previous"
              ></a>
              <a
                className="uk-position-center-right uk-position-small uk-hidden-hover slider-arrow next-arrow"
                href="#"
                data-uk-slidenav-next
                data-uk-slider-item="next"
              ></a>
            </div>
          </div>
          <div className="uk-card uk-text-center review-text-card">
            <div
              className="bg-image"
              style={{
                backgroundImage: `url("${process.env.PUBLIC_URL}/images/image13.jpg")`,
              }}
            ></div>
            <div className="uk-card-body">
              <h1 className="uk-heading-small uk-text-bold review-text">
                A high performance multi-disciplinary scientific & technology
                driven consulting company in Africa.
              </h1>
            </div>
          </div>
        </div>
      </div>


      {/* selected clients 
      <div className="uk-section client-section">
        <div className="uk-container uk-container-large">
          <div className="selected-client-title">
            <h3 className="uk-card-title">Selected Clients</h3>
          </div>
           <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex={-1} data-uk-slider="sets: true; autoplay: true" >
            <ul className="uk-margin-bottom uk-slider-items uk-grid uk-grid-small uk-grid-match" data-uk-grid>
              {clientsData.map((client) => (
                <li key={client.id}>
                  <div className="selected-clients-card uk-card  uk-padding-small uk-card-default">
                    <div className="uk-text-center">
                      <img
                        src={client.photo}
                        alt=""
                        width={150}
                        height={80}
                      />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <a
              className="uk-position-center-left uk-position-small uk-hidden-hover slider-arrow previous-arrow"
              href="#"
              data-uk-slidenav-previous
              data-uk-slider-item="previous"
            ></a>
            <a
              className="uk-position-center-right uk-position-small uk-hidden-hover slider-arrow next-arrow"
              href="#"
              data-uk-slidenav-next
              data-uk-slider-item="next"
            ></a>
          </div> 
       <div className="uk-margin-large-top uk-card selected-client-title">
            <h3 className="uk-card-title">Client Reviews</h3>
          </div> *
         <div className="uk-card uk-margin-top client-section-2">
            <div
              className="uk-position-relative uk-visible-toggle uk-light"
              tabIndex={-1}
              data-uk-slider="sets: true;autoplay:true"
            >
              <ul
                className="uk-slider-items uk-grid uk-grid-collapse uk-grid-small uk-grid-match uk-child-width-1-2@m uk-child-width-1-3@l"
                data-uk-grid
              >
                {clientCardData.map((client) => (
                  <li key={client.id}>
                    <div className="uk-card uk-card-default uk-padding-small">
                      <div
                        className="uk-grid-small uk-flex-middle"
                        data-uk-grid
                      >
                        <div className="uk-width-auto">
                          <div className="uk-border-circle abbrev">
                            {client.abbrev}
                          </div>
                        </div>
                        <div className="uk-width-expand">
                          <p className="uk-text-bolder uk-margin-remove-bottom">
                            {client.fullname}
                          </p>
                          <p className="uk-text-small uk-margin-remove-top">
                            {client.company}
                          </p>
                        </div>
                      </div>
                      <p className="uk-text-small ">{client.description}</p>
                    </div>
                  </li>
                ))}
              </ul>
              <a
                className="uk-position-center-left uk-position-small uk-hidden-hover slider-arrow previous-arrow"
                href="#"
                data-uk-slidenav-previous
                data-uk-slider-item="previous"
              ></a>
              <a
                className="uk-position-center-right uk-position-small uk-hidden-hover slider-arrow next-arrow"
                href="#"
                data-uk-slidenav-next
                data-uk-slider-item="next"
              ></a>
            </div>
          </div>
           <div className="uk-card uk-text-center review-text-card">
            <div
              className="bg-image"
              style={{
                backgroundImage: `url("${process.env.PUBLIC_URL}/images/image13.jpg")`,
              }}
            ></div>
            <div className="uk-card-body">
              <h1 className="uk-heading-small uk-text-bold review-text">
                A high performance multi-disciplinary scientific & technology
                driven consulting company in Africa.
              </h1>
            </div>
          </div> 
        </div>
      </div>*/}
      <div
        className="uk-section contact-page"
        id="contact"
        style={{
          backgroundImage: `url("${process.env.PUBLIC_URL}/images/image14.jpg")`,
        }}
      >
        <div className="uk-container uk-container-large">
          <div className="page-title">
            <span className="uk-margin-right">07</span>CONTACT
          </div>
          <div className="uk-card">
            <div className="uk-card">
              <h3 className="contact-title">Get in Touch</h3>
            </div>
            <div
              className="contacts-card uk-grid uk-grid-collapse uk-grid-match uk-child-width-1-3@m"
              data-uk-grid
            >
              <div>
                <div className="uk-card uk-card-default uk-card-body ">
                  <div className="uk-flex">
                    <div>
                      <span
                        data-uk-icon="icon: receiver"
                        className="uk-margin-small-right"
                      ></span>
                    </div>
                    <div>
                      <a href="tel:+264 061 259 530">
                        Tell: +264 (0) 61 259 530
                      </a>
                      <br />
                      <a href="tel:+264 (0)81 152 4420">
                        Cell: +264 (0) 81 152 4420
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="uk-card uk-card-default uk-card-body">
                  <div className="uk-flex">
                    <div>
                      <span
                        data-uk-icon="icon: mail"
                        className="uk-margin-small-right"
                      ></span>
                    </div>
                    <div>
                      <a href="mailto:info@edsnamibia.com">
                        info@edsnamibia.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="uk-card uk-card-default uk-card-body">
                  <div className="uk-flex">
                    <div>
                      <span
                        data-uk-icon="icon: location"
                        className="uk-margin-small-right"
                      ></span>
                    </div>
                    <div>
                      <p> 5th Floor Marua Mall, Office Block B, Windhoek, Namibia.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="uk-grid-small uk-grid-match uk-child-width-1-2@s"
              data-uk-grid
            >
              <div className="contact-form-page">
                <form
                  className="contact-form uk-form-stacked uk-card uk-card-default uk-card-body"
                  onSubmit={onSubmit}
                >
                  <div className="uk-flex">
                    <div className="uk-width-1-1@s">
                      <label
                        className="uk-form-label  uk-margin-top"
                        htmlFor="Firstname"
                      >
                        Full Name
                      </label>
                      <input
                        className="uk-input uk-form-medium"
                        type="text"
                        value={contactForm.fullname}
                        onChange={(e) =>
                          setContactForm({
                            ...contactForm,
                            fullname: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="uk-flex">
                    <div className="uk-width-1-1@s">
                      <label
                        className="uk-form-label  uk-margin-top"
                        htmlFor="belong"
                      >
                        Organisation or Individual
                      </label>
                      <select
                        className="uk-select"
                        id="belong"
                        value={contactForm.belong}
                        onChange={(e) =>
                          setContactForm({
                            ...contactForm,
                            belong: e.target.value,
                          })
                        }
                      >
                        <option value={"Organisation"}>Organisation</option>
                        <option value={"Individual"}>Individual</option>
                      </select>
                    </div>
                  </div>
                  <div className="uk-width-1-1@s">
                    <label
                      className="uk-form-label  uk-margin-top"
                      htmlFor="email"
                    >
                      Email Address
                    </label>
                    <input
                      id="email"
                      className="uk-input uk-form-medium"
                      type="email"
                      value={contactForm.email}
                      onChange={(e) =>
                        setContactForm({
                          ...contactForm,
                          email: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div className="uk-width-1-1@s">
                    <label
                      className="uk-form-label  uk-margin-top"
                      htmlFor="Subject"
                    >
                      Subject
                    </label>
                  </div>
                  <div className="uk-form-controls">
                    <select
                      className="uk-select"
                      id="form-horizontal-select"
                      value={contactForm.subject}
                      onChange={(e) =>
                        setContactForm({
                          ...contactForm,
                          subject: e.target.value,
                        })
                      }
                      required
                    >
                      <option value={"Water and energy consulting"}>
                        Water and energy consulting
                      </option>
                      <option value={"Research and Training"}>
                        Research and Training
                      </option>
                      <option value={"Project management"}>
                        Project management
                      </option>
                      <option value={"Geology"}>Geology</option>
                      <option value={"Geotechnical and Environmental Services"}>
                        Geotechnical and Environmental Services
                      </option>
                      <option value={"Business Reengineering"}>
                        Business Reengineering
                      </option>
                      <option value={"Big Data Analysis, Management"}>
                        Big Data Analysis, Management
                      </option>
                      <option value={"Management"}>Management</option>
                      <option value={"Modeling"}>Modelling</option>
                      <option value={"GIS"}>GIS</option>
                      <option value={"Others"}>Others</option>
                    </select>
                  </div>
                  <div className="uk-width-1-1@s">
                    <label className="uk-form-label uk-margin-top">
                      Message
                    </label>
                    <textarea
                      className="uk-textarea"
                      rows={5}
                      value={contactForm.message}
                      onChange={(e) =>
                        setContactForm({
                          ...contactForm,
                          message: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div className="uk-margin form-button">
                    <button className="uk-button sign-up-button" type="submit">
                      Submit
                      {loading && (
                        <div
                          className="uk-margin-small-left"
                          data-uk-spinner="ratio: 0.5"
                        ></div>
                      )}
                    </button>
                  </div>
                </form>
              </div>
              <div className="uk-margin-remove-left map">
                <div className="uk-card uk-card-default">
                  <div className="mapouter">
                    <div className="gmap_canvas">
                      <iframe
                        title="excel dymanic solutions location"
                        className="uk-width-1-1"
                        height="500"
                        id="gmap_canvas"
                        src="https://maps.google.com/maps?q=EXEL%20DYNAMIC%20SOLUTION&t=&z=13&ie=UTF8&iwloc=&output=embed"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="contacts-card uk-grid  uk-child-width-1-1"
              data-uk-grid
            >
              <div>
                <div className="uk-card uk-card-default uk-card-body">
                  <div>
                    <p className="uk-margin-small uk-text-bold">Documents</p>
                  </div>
                  <div>
                    <a className="download" href={disclaimer} download>
                      Company Profile PDF
                      <span uk-icon="icon: download; ratio: .8"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
