export const teamData = [
  {
    id: "01",
    photo: `${process.env.PUBLIC_URL}/images/team/Nerson.jpg`,
    name: "Nerson Tjelos",
    role: "Business Development Lead",
  },
  {
    id: "04",
    photo: `${process.env.PUBLIC_URL}/images/team/Rose.jpg`,
    name: "Rose Mtuleni",
    role: "Environmentalist",
  },

  {
    id: "06",
    photo: `${process.env.PUBLIC_URL}/images/team/Beukes.jpg`,
    name: "Le’Nischa Beukes",
    role: "Assistant Data Analyst",
  },
  {
    id: "08",
    photo: `${process.env.PUBLIC_URL}/images/team/Silas.jpg`,
    name: "Silas David",
    role: "GIS Operator",
  },

  {
    id: "11",
    photo: `${process.env.PUBLIC_URL}/images/team/Fillemon.jpg`,
    name: "Fillemon Shilongo",
    role: "",
  },
  {
    id: "13",
    photo: `${process.env.PUBLIC_URL}/images/team/Iyaloo.png`,
    name: "Iyaloo Nakale",
    role: "Environmentalist",
  },
  {
    id: "14",
    photo: `${process.env.PUBLIC_URL}/images/team/Stefanus.jpg`,
    name: "Stefanus Johannes",
    role: "GIS Technologist",
  },
  {
    id: "15",
    photo: `${process.env.PUBLIC_URL}/images/team/Mandume.jpg`,
    name: "Mandume Leonard",
    role: "Environmentalist",
  },

  {
    id: "17",
    photo: `${process.env.PUBLIC_URL}/images/team/Aili.jpg`,
    name: "Aili Iipinge",
    role: "Environmentalist",
  },
  {
    id: "18",
    photo: `${process.env.PUBLIC_URL}/images/team/Roland.jpg`,
    name: "Roland Mushi",
    role: "Archaeologist",
  },
  {
    id: "19",
    photo: `${process.env.PUBLIC_URL}/images/team/Amelia.jpg`,
    name: "Amelia Nikodemus",
    role: "GIS Expert",
  },
  {
    id: "20",
    photo: `${process.env.PUBLIC_URL}/images/team/Hilya.jpg`,
    name: " Hilya Henok",
    role: "W",
  },
  {
    id: "03",
    photo: `${process.env.PUBLIC_URL}/images/team/Matti.jpeg`,
    name: "Matti Kaapangelwa",
    role: "Finance Executive",
  },
];
