import { observer } from "mobx-react-lite";
import { Link, useParams } from "react-router-dom";
import { serviceData } from "../data/services/serviceData";

export interface IService {
  id: string;
  backgroundImg: string;
  icon: string;
  title: string;
  description: string;
  title1: string;
  secondDescrip: string;
  title2: string;
  firstCards: { icon: string; content: string }[];
  title3: string;
  secondCards: { icon: string; content: string }[];
  title4: string;
  thirdDescrip: string;
  thirdCards: { icon: string; title: string; description: string }[];
  title5: string;
  fourthDescrip: string;
  listTitle: string;
  list1: { content: string }[];
}

interface IProps {
  service: IService;
}

export const ServiceCard = (props: IProps) => {
  const { service } = props;

  return (
    <div className="service-card uk-card uk-card-default">
      <div className="uk-card-body uk-text-center">
        <div>
          <img
            src={service.icon}
            alt=""
            className="icon"
            width={50}
            height={50}
          />
        </div>
        <p className="uk-text-bold uk-text-secondary">{service.title}</p>

        <p
          className="uk-text-small uk-margin-bottom"
          dangerouslySetInnerHTML={{ __html: service.description }}
        />
        <Link
          to={`/${service.id}`}
          className="read-more-button uk-button uk-button-text uk-position-bottom uk-padding-bottom"
        >
          Read more
        </Link>
      </div>
    </div>
  );
};

const ServicesDetailtemOverlay = (props: IProps) => {
  const { service } = props;
  return (
    <div>
      <div className="uk-section service-section-overlay">
        <div className="uk-container uk-container-large">
          <div className="background-overlay">
            <img src={service.backgroundImg} alt="" />
            <div className="overlay">
              <h2 className="landing-title">{service.title}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ServicesDetailtem = (props: IProps) => {
  const { service } = props;
  return (
    <div className="service-detail">
      {service.description.length !== 0 && (
        <div className="uk-card uk-background-muted uk-child-width-1-1@m">
          <div className="bottom-content uk-card-body">
            <div>
              <p dangerouslySetInnerHTML={{ __html: service.description }}></p>
            </div>
          </div>
        </div>
      )}
      {service.secondDescrip !== "" && (
        <>
          <h2 className="uk-margin-top">{service.title1}</h2>
          <div className="uk-card uk-background-muted uk-child-width-1-1@m">
            <div className="bottom-content uk-card-body">
              <div>
                <p>{service.secondDescrip}</p>
              </div>
            </div>
          </div>
        </>
      )}
      {service.title2 !== "" && (
        <h2 className="uk-margin-top">{service.title2}</h2>
      )}

      <div
        className="uk-child-width-1-4@m uk-grid uk-grid-small uk-grid-match"
        data-uk-grid
        data-uk-scrollspy="target: .service-detail-card; cls: uk-animation-slide-bottom; delay: 100"
      >
        {service.firstCards.length !== 0 &&
          service.firstCards.map((card, index) => (
            <div key={index}>
              <div className="uk-card service-detail-card uk-card-default uk-card-body">
                <div className="uk-text-center">
                  <div>
                    <img
                      src={card.icon}
                      alt=""
                      className="icon"
                      width={50}
                      height={50}
                    />
                  </div>
                  <p
                    className="uk-text-small"
                    dangerouslySetInnerHTML={{ __html: card.content }}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
      {service.title3.length !== 0 && (
        <h2 className="uk-margin-top">
          <a
            href="https://forms.gle/eK19FiBeRh4rK4Ln8"
            target="_blank"
            rel="noreferrer"
          >
            {service.title3}
          </a>
        </h2>
      )}
      <div
        className="uk-child-width-1-3@m uk-grid uk-grid-small uk-grid-match uk-margin-bottom"
        data-uk-grid
        data-uk-scrollspy="target: .service-detail-card; cls: uk-animation-slide-bottom; delay: 100"
      >
        {service.secondCards.length !== 0 &&
          service.secondCards.map((card, index) => (
            <div key={index}>
              <div className="uk-card service-detail-card uk-card-default uk-card-body">
                <div className="uk-text-center">
                  <div>
                    <img
                      src={card.icon}
                      alt=""
                      className="icon"
                      width={50}
                      height={50}
                    />
                  </div>
                  <p className="uk-text-small">{card.content}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
      {service.title4.length !== 0 && (
        <h2 className="uk-margin-top">{service.title4}</h2>
      )}
      {service.thirdDescrip !== "" && (
        <div className="uk-card uk-background-muted uk-child-width-1-1@m">
          <div className="bottom-content uk-card-body">
            <div>
              <p dangerouslySetInnerHTML={{ __html: service.thirdDescrip }} />
            </div>
          </div>
        </div>
      )}
      <div
        className="uk-child-width-1-3@m uk-grid uk-grid-small uk-grid-match"
        data-uk-grid
        data-uk-scrollspy="target: .service-detail-card; cls: uk-animation-slide-bottom; delay: 100"
      >
        {service.thirdCards.length !== 0 &&
          service.thirdCards.map((card, index) => (
            <div key={index}>
              <div className="uk-card service-detail-card uk-card-default uk-card-body">
                <div className="uk-text-center">
                  <div>
                    <img
                      src={card.icon}
                      alt=""
                      className="icon"
                      width={50}
                      height={50}
                    />
                  </div>
                  <h3 className="uk-card-title uk-text-small uk-text-bold">
                    {card.title}
                  </h3>
                  <p className="uk-text-small">{card.description}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
      {service.list1.length !== 0 && (
        <div className="uk-card uk-background-muted uk-child-width-1-1@m">
          <div className="uk-card-body">
            <p>{service.listTitle}</p>
            <ul>
              {service.list1.map((list, index) => (
                <li key={index}>{list.content}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <h2>{service.title5}</h2>
      <div className="uk-card uk-background-muted uk-child-width-1-1@m">
        <div className="bottom-content uk-card-body">
          <div>
            <p dangerouslySetInnerHTML={{ __html: service.fourthDescrip }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ServiceDetail = observer(() => {
  const { id } = useParams<{ id: string }>();
  const data = serviceData.filter((service) => service.id === id);

  return (
    <>
      <ServicesDetailtemOverlay service={data[0]} />
      <div className="uk-section service-detail-section">
        <div className="uk-container uk-container-large">
          <div className="uk-card uk-margin-top uk-margin-bottom">
            <Link
              to={`/`}
              className="uk-button services-button uk-button-text uk-position-bottom"
            >
              Back
            </Link>
          </div>
          <div className="uk-card">
            <ServicesDetailtem service={data[0]} />
          </div>
          <div className="uk-card">
            <Link
              to={`/`}
              className="uk-button services-button uk-button-text uk-position-bottom"
            >
              Back
            </Link>
          </div>
        </div>
      </div>
    </>
  );
});
