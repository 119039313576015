const path = `${process.env.PUBLIC_URL}`;

export const serviceData = [
  {
    id: "geology-geotechnical-and-environmental-services",
    backgroundImg: `${path}/images/image14.jpg`,
    icon: `${process.env.PUBLIC_URL}/icons/194.png`,
    title: "Geology, Mining & Environmental Solutions",
    description: `For a variety of clients ranging from mineral rights owners,
    construction & engineering companies, parastatals to large
    corporations, EDS utilizes its professionals' experience &
    knowledge to provide environmentally responsible solutions
    to compliance, geological and engineering concerns.`,
    title1: "",
    secondDescrip: ``,

    title2: "Geological & Mining Solutions",
    firstCards: [
      {
        icon: `${path}/icons/174.png`,
        content: `Prospecting and mining rights application.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Geological field mapping.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Exploration project development and scoping.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Management of all geological functions required to support a functional mine.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Geological feasibility studies.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Geotechnical assessments for both underground and surface workings.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Soil sampling.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Building of geological block models, with in-house software.`,
      },
    ],
    title3: "Geotechnical Solutions",
    secondCards: [
      {
        icon: `${path}/icons/174.png`,
        content: `Geotechnical site investigations.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Materials sampling for lab work.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Management of all geotechnical functions required in mining and construction industries.`,
      },
    ],
    title4: "Environmental Assessment Solutions",
    thirdDescrip: ``,
    thirdCards: [
      {
        icon: `${path}/icons/174.png`,
        title: ``,
        description: "Planning, executing, and managing interested and affected party meetings.",
      },
      {
        icon: `${path}/icons/174.png`,
        title: ``,
        description: `Environmental impact assessments, strategic environmental assessment, socio-economic assessments, & contaminated land assessments.`,
      },
      {
        icon: `${path}/icons/174.png`,
        title: ``,
        description: `Archeological and heritage impact assessments`,
      },
      {
        icon: `${path}/icons/174.png`,
        title: ``,
        description: `Development of environmental management programs.`,
      },
      {
        icon: `${path}/icons/174.png`,
        title: ``,
        description: `Selection and management of mitigation and remediation processes.`,
      },
      {
        icon: `${path}/icons/174.png`,
        title: ``,
        description: `Environmental audits and monitoring.`,
      },
      {
        icon: `${path}/icons/174.png`,
        title: ``,
        description: `Health and safety planning and training.`,
      },
      {
        icon: `${path}/icons/174.png`,
        title: ``,
        description: `Climate research and climate risk advisory service.`,
      },
      {
        icon: `${path}/icons/174.png`,
        title: ``,
        description: `Planning, executing oil spill hazards and risks assessments.`,
      },
      {
        icon: `${path}/icons/174.png`,
        title: ``,
        description: `Oil spill response management and emergency training.`,
      },
      {
        icon: `${path}/icons/174.png`,
        title: ``,
        description: `Oil spill contingency planning.`,
      },
      {
        icon: `${path}/icons/174.png`,
        title: ``,
        description: `Purchasing and rental of oil spill management equipment.`,
      },
      {
        icon: `${path}/icons/174.png`,
        title: ``,
        description: `Business continuity planning.`,
      },
    ],
    title5: "",
    fourthDescrip: ``,

    listTitle: ``,
    list1: [],
  },
  {
    id: "big-data-analysis-management-modeling-and-gis",
    backgroundImg: `${path}/images/image16.jpg`,
    icon: `${process.env.PUBLIC_URL}/icons/94.png`,
    title: "Data Analysis, Management, Modeling, & GIS Solutions",
    description: `In order to responsibly manage data, formal concepts, principles, and techniques must be applied, 
    regardless of whether it is used or created. In the absence of proper data management,
     current and future business activities become less valuable. With a high-quality data resource, 
     it is possible to analyze historical trends and patterns, perform current business operations and 
     make operational decisions, and evaluate future business options and opportunities.`,
    title1: "",
    secondDescrip: ``,
    title2: "",
    firstCards: [
      {
        icon: `${path}/icons/174.png`,
        content: `Conduct data collection on behalf of clients.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Do data entry and cleansing.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Create and enforce policies for effective data management.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Assist with reports and data extraction when needed.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Establish rules and procedures for data sharing managerial entities, external stakeholders and any other way as needed.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Provide Support in the daily use of data systems and ensure adherence to legal and company standards.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Formulate techniques for quality data collection to ensure adequacy, accuracy, and legitimacy of data.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Monitor and analyze information and data systems and evaluate their performance to discover ways of enhancing them (innovative technologies, upgrades and much more.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Ensure digital databases and archives are protected from security breaches and data losses.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Troubleshoot data-related problems and authorize maintenance or modifications`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Devise and implement efficient and secure procedures for data handling and analysis with attention to all technical aspects.`,
      },
    ],
    title3: "GIS",
    secondCards: [{
      icon: `${path}/icons/174.png`,
      title: ``,
      content: `Mobile Data Collection.`,
    },
    {
      icon: `${path}/icons/174.png`,
      title: ``,
      content: `Cartography, Atlases, Interactive maps.`,
    },
    {
      icon: `${path}/icons/174.png`,
      title: ``,
      content: `Digital Mapping and 3D visualization of surfaces/sub-surfaces.`,
    },
    {
      icon: `${path}/icons/174.png`,
      title: ``,
      content: `Database Development and Management.`,
    },],
    title4: "",
    thirdDescrip: `With the EDS GIS solution we create, manage, analyze, and map all types of data.`,
    thirdCards: [],
    title5: "",
    fourthDescrip: ``,
    listTitle: ``,
    list1: [],
  },
  {
    id: "water-and-energy-consulting",
    backgroundImg: `${path}/images/image12.jpg`,
    icon: `${process.env.PUBLIC_URL}/icons/174.png`,
    title: "Water Solutions",
    description: `EDS provides custom-engineered solutions for water and
    energy management. Through the technology behind water and
    energy management solutions that EDS has engineered over the
    years, the set-up and configurations are flexible,
    efficient, and affordable.`,
    title1: "Hydrological Solutions",
    secondDescrip: `The team has experience in surface and groundwater exploration.
     Our hydrogeologists, climate scientists and water quality scientists help you 
     manage your water needs and meet your environmental goals. Whether evaluating
      water availability for your project’s needs or assessing potential project 
      impacts on surface water or groundwater, EDS provides cost-effective solutions 
      to characterize and monitor your water resources.`,

    title2: "",
    firstCards: [
      {
        icon: `${path}/icons/174.png`,
        content: `Application of hydrologic, hydraulic, and hydrogeologic models to support environmental impact assessments.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Data management, including access to water quality, level, and flow data and climate data.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Characterization of watersheds, aquifers, hydrologic properties, and flow regimes.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Design and install monitoring networks, including telemetry and near real-time remote data acquisition.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Boreholes Surverying siting and rehabilitation assessment.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Pumping and water quality tests..`,
      },
    ],
    title3: "",
    secondCards: [],
    title4: "",
    thirdDescrip: ``,
    thirdCards: [],
    title5: "",
    fourthDescrip: ``,
    listTitle: ``,
    list1: [],
  },
  {
    id: "project-management",
    backgroundImg: `${path}/images/image3.jpg`,
    icon: `${process.env.PUBLIC_URL}/icons/72.png`,
    title: "Project Management Solutions",
    description: `<p>By offering innovative and strategic project management services, we are able to deliver projects and programs on time without disrupting business operations. As part of our initiative-taking planning, we identify what is really important in projects, and we work diligently to make them successful. 
    <p/><p>Our team coordinates project planning in accordance with the PMBOK standards in collaboration with the client and all other stakeholders. Every project undergoes this process. As part of our Project Management services, we can also assist clients in generating new projects and prospects.<p/>`,
    title1: "",
    secondDescrip: ``,

    title2: "Our Services",
    firstCards: [
      {
        icon: `${path}/icons/174.png`,
        content: `Project Selection.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Planning (Scheduling, costing, resource planning).`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Rollout and execution.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Monitoring and control.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Change management.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Risk evaluation and aversion.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Standardization.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Reporting.`,
      },
    ],
    title3: "",
    secondCards: [],
    title4: "",
    thirdDescrip: ``,
    thirdCards: [],
    title5: "",
    fourthDescrip: ``,
    listTitle: ``,
    list1: [],
  },
  {
    id: "research-and-training",
    backgroundImg: `${path}/images/image13.jpg`,
    icon: `${process.env.PUBLIC_URL}/icons/64.png`,
    title: "Research & Training Solutions",
    description: `We offer customized GIS, Remote Sensing, emerging
    technologies, and data management training programs built on
    outcome-driven or standard-based research. Individuals and
    organizations can make calls throughout the year. Training
    is provided on all our services, as well as third-party
    software and custom software developed for clients.`,
    title1: "",
    secondDescrip: ``,
    title2: "Get Started",
    firstCards: [
      {
        icon: `${path}/icons/174.png`,
        content: `Contact EDS and fill in the form for the course of your interest.`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Select if the course will be online or face to face`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `We will send you the duration and course content based on your specifications`,
      },
      {
        icon: `${path}/icons/174.png`,
        content: `Get a certificate of completion`,
      },
    ],
    title3: "Fill in the form",
    secondCards: [],
    title4: "",
    thirdDescrip: ``,
    thirdCards: [],
    title5: "",
    fourthDescrip: ``,
    listTitle: `Our solution is targeted towards the following organizations and Individuals in the following fields: Exploration and mining`,
    list1: [
      {
        content: `Agriculture`,
      },
      {
        content: `Climate change and environment`,
      },
      {
        content: `Disaster management`,
      },
      {
        content: `Education`,
      },
      {
        content: `Food and nutrition`,
      },
      {
        content: `Health`,
      },
      {
        content: `Coordination and telecommunication`,
      },
      {
        content: `Construction and real estates`,
      },
      {
        content: `Safety and security`,
      },
      {
        content: `Water sanitation and hygiene sectors`,
      },
    ],
  },
];
