import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

const BrandLogo = () => {
  return (
    <div className="brand uk-margin">
      <img src={`${process.env.PUBLIC_URL}/images/Logo.png`} alt="" />
    </div>
  );
};

type IAnimateProps = {
  targetPosition: number;
  initialPosition: number;
  duration: number;
};

const DrawerList = observer(() => {
  const pow = Math.pow;
  const navigate = useNavigate();

  function easeOutQuart(x: number) {
    return 1 - pow(1 - x, 4);
  }

  function animateScroll(props: IAnimateProps) {
    const { targetPosition, initialPosition, duration } = props;
    let start: number | undefined;
    let position;
    let animationFrame: number;

    const requestAnimationFrame = window.requestAnimationFrame;
    const cancelAnimationFrame = window.cancelAnimationFrame;

    const maxAvailableScroll =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const amountOfPixelsToScroll = initialPosition - targetPosition;

    function step(timestamp: number) {
      if (start === undefined) {
        start = timestamp;
      }

      const elapsed = timestamp - start;
      const relativeProgress = elapsed / duration;
      const easedProgress = easeOutQuart(relativeProgress);
      position =
        initialPosition - amountOfPixelsToScroll * Math.min(easedProgress, 1);

      window.scrollTo(0, position);
      if (
        initialPosition !== maxAvailableScroll &&
        window.scrollY === maxAvailableScroll
      ) {
        cancelAnimationFrame(animationFrame);
        return;
      }
      if (elapsed < duration) {
        animationFrame = requestAnimationFrame(step);
      }
    }
    animationFrame = requestAnimationFrame(step);
  }

  const getElementPosition = (element: HTMLElement) => {
    return element.offsetTop ? element.offsetTop : 0;
  };

  const scrollTo = ({ id = "", ref = null, duration = 3000 }) => {
    const initialPosition = window.scrollY;

    if (id) {
      const element = document.getElementById(id) as HTMLElement;

      if (!element) {
        navigate("/");
        return;
      }
      animateScroll({
        targetPosition: getElementPosition(element),
        initialPosition,
        duration,
      });
    }
  };

  return (
    <div className="drawer-list">
      <ul className="main-list uk-nav-default" data-uk-nav>
        <li className="list-item">
          <div onClick={() => scrollTo({ id: "excel-dynamic-solutions" })} className="navlink">
            Home <span className="line" />
            <span className="number">01</span>
          </div>
        </li>
        <li className="list-item">
          <div onClick={() => scrollTo({ id: "about" })} className="navlink">
            About <span className="line" />
            <span className="number">02</span>
          </div>
        </li>
        <li className="list-item">
          <div onClick={() => scrollTo({ id: "services" })} className="navlink">
            Services
            <span className="number">03</span>
          </div>
        </li>
        <li className="list-item">
          <div onClick={() => scrollTo({ id: "awards" })} className="navlink">
            Awards
            <span className="number">04</span>
          </div>
        </li>
        <li className="list-item">
          <div onClick={() => scrollTo({ id: "team" })} className="navlink">
            Team <span className="number">05</span>
          </div>
        </li>
        <li className="list-item">
          <div onClick={() => scrollTo({ id: "clients" })} className="navlink">
            Clients <span className="number">06</span>
          </div>
        </li>
        <li className="list-item">
          <div onClick={() => scrollTo({ id: "contact" })} className="navlink">
            Contact <span className="number">07</span>
          </div>
        </li>
      </ul>
    </div>
  );
});

const Drawer = () => {
  return (
    <div className="drawer">
      <div id="navbar-drawer" data-uk-offcanvas="overlay: true">
        <div className="uk-offcanvas-bar">
          <button
            className="uk-offcanvas-close"
            type="button"
            data-uk-close
          ></button>
          <BrandLogo />
          <DrawerList />
        </div>
      </div>
      <div className="fixed-drawer uk-visible@s">
        <BrandLogo />
        <DrawerList />
      </div>
    </div>
  );
};

export default Drawer;
