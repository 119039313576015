export const homeData = [
  {
    id: "01",
    photo: `${process.env.PUBLIC_URL}/images/image16.jpg`,
    title: "Excel Dynamic Solutions (PTY) LTD",
    description:
      "Earth Scientists | Data Experts | Project Managers | Business Engineers",
  },
  {
    id: "02",
    photo: `${process.env.PUBLIC_URL}/images/new.jpg`,
    title: "Excel Dynamic Solutions (PTY) LTD",
    description:
      "Earth Scientists | Data Experts | Project Managers | Business Engineers",
  },
  {
    id: "03",
    photo: `${process.env.PUBLIC_URL}/images/image17.jpg`,
    title: "Excel Dynamic Solutions (PTY) LTD",
    description:
      "Earth Scientists | Data Experts | Project Managers | Business Engineers",
  },
];

// https://www.youtube.com/watch?v=Ee0Qh_nIoHw
// ${process.env.PUBLIC_URL}/images/cars-and-the-environment.mp4
// cars-and-the-environment.gif
// https://firebasestorage.googleapis.com/v0/b/eds-mining-portal.appspot.com/o/video%2Fcars-and-the-environment.mp4?alt=media&token=539db3af-5773-4b85-bd4c-5322622abccc
// photo: `${process.env.PUBLIC_URL}/images/cars-and-the-environment.mp4`,
